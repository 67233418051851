import {memo, forwardRef, useState, useRef, useEffect, useImperativeHandle} from 'react';
import { DateTime } from "luxon";

export const TimePickerEditor = memo(
    forwardRef((props, ref) => {
        const [value, setValue] = useState(props.value);
        const refInput = useRef(null);
        let valueToDisplay = null;

        useEffect(() => {
            refInput.current.focus();
        },[]);

        useImperativeHandle(ref, () => {
            return {
                getValue() {
                    if(value.length < 6) {
                        // convert back for AGGrid from 22:00 to e.g. 2025-01-22T22:00:00.000+01:00 to 22:00
                        try {
                            let d = DateTime.fromISO(props.data.date);
                            let timeArr = value.split(':');
                            return d.set({hour: timeArr[0], minute: timeArr[1]}).toISO();
                        } catch(e) {
                            console.log('exception in getValue creating datetime',e)
                        }
                        
                    } else {
                        return value;
                    }
                    
                },
                isCancelBeforeStart() {
                    return false;
                },
                isCancelAfterEnd() {
                    return false;
                }
            }
        });

        
        // convert from e.g. 2025-01-22T22:00:00.000+01:00 to 22:00
        //console.log('startValue',value);
        //try {
            valueToDisplay = DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_SIMPLE)
        //} catch(e) {
        //    console.log('exception initting', value, e.message);
        //}

        return (
            <input
                type="time"
                ref={refInput}
                value={valueToDisplay}
                onChange={(event) => setValue(event.target.value)}
                onDoubleClick={(event) => props.api.stopEditing()}
                step="900"
            />
        )
    })
)