import React, { useEffect, useRef, useState, useCallback } from "react";
import "./TimeRecords.scss";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Alert } from "antd";
import DateAndTimePickerEditor from "./celleditors/DateAndTimePickerEditor.js";
import OpenprojectWorkPackageSelectorEditor from "./celleditors/OpenprojectWorkPackageSelectorEditor.js";
import ClassificationEditor from "./celleditors/ClassificationEditor.js"
import BoolRenderer from "./cellrenderers/BoolRenderer.js";
import OpenprojectWorkPackageSelectorRenderer from "./cellrenderers/OpenprojectWorkPackageSelectorRenderer.js";
import TimeRecordsButtonToolbar from "./TimeRecordsButtonToolbar.js";
import CRUDController from "../../controllers/CRUDController.js";
import _, { filter } from "lodash";
import { TimePickerEditor } from "./celleditors/TimePickerEditor.js";
import { DateTime } from "luxon";


export default function TimeRecords(props) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const columnDefs = initColumnDefs();
    const defaultColDef = initDefaultColDef();
    const [rowData, setRowData] = useState(null);
    const columnTypes = initColumnTypes();

    const [loading, setLoading] = useState(true);
    const [gridDirty, setGridDirty] = useState(false);
    const [filterModel, setFilterModel] = useState(null);
    const [columnState, setColumnState] = useState(null);
    const [feedbackMsg, setFeedbackMsg] = useState("");
    const [feedbackIsError, setFeedbackIsError] = useState(false);
    const [tempId, setTempId] = useState(0);

    // TODO: find better way to get rid of hack
    // NOTE/HACK: because the rest of the code unfortunately mixes reactive code and functions/closures in hard-to-reason-about ways,
    // we need to keep a reference to the openprojectWorkpackages, so that you can access the up-to-date workpackages data inside of the functions below 
    // (such as initColumnTypes)
    // using a regular useState() won't work, because of "stale-closures"
    // const [openprojectWorkpackages, setOpenprojectWorkpackages] = useState([]);
    const openprojectWorkpackagesRef = useRef([]);
    const [hours, setHours] = useState(0);
    const [rows, setRows] = useState(0);

    // status objects
    const rowStatus = {
        new: { id: 0, name: "New" },
        edited: { id: 1, name: "Edit" },
        clean: { id: 2, name: "Clean" },
        deleted: { id: 3, name: "Del" },
        error: { id: 4, name: "Err" },
    };

    const _CRUDController = new CRUDController(props.debugLog);
    

    // NOTE: require dynamic callback to avoid stale closures, see https://www.ag-grid.com/react-grid/fine-tuning/#avoiding-stale-closures-ie-old-props-values why
    function useDynamicCallback(callback) {
        const ref = useRef();
        ref.current = callback;
        return useCallback((...args) => ref.current.apply(this, args), []);
    }

    const [filterProductiveOnly, setFilterProductiveOnly] = useState(false);
    const [filterMonthRelative, setFilterMonthRelative] = useState(null); // null = allTime, 0 = thisMonth, -1 = lastMonth

    const externalFilterPass = useDynamicCallback((node) => {
         // ignore new rows, always show them
        if (node.data.status.id === rowStatus.new.id)
            return true;

        // check productive filter
        if (filterProductiveOnly) {
            if (!node.data.productive)
                return false;
        }
        // check relative month filter
        if (filterMonthRelative !== null) {
            let dateFrom = DateTime.now().plus({month: filterMonthRelative}).startOf('month').toISO();
            let dateTo = DateTime.now().plus({month: filterMonthRelative}).endOf('month').toISO();
            let date = node.data.date;
            
            if (date < dateFrom || date > dateTo)
                return false;
        }
        return true;
    });

    
    // initialize external filter with saved localstorage data on load
    useEffect(() => {
        const filterProductiveOnlyStr = localStorage.getItem("filterProductiveOnly");
        if (filterProductiveOnlyStr !== null) setFilterProductiveOnly(JSON.parse(filterProductiveOnlyStr));
        const filterMonthRelativeStr = localStorage.getItem("filterMonthRelative");
        if (filterMonthRelativeStr !== null) setFilterMonthRelative(JSON.parse(filterMonthRelativeStr));
    }, []);

    // on (external) filter change
    useEffect(() => {
        // we manually tell aggrid that our (external) filters have changed
        if (gridApi) {
            gridApi.onFilterChanged();
        }

        // save the (external) filters whenever they change
        localStorage.setItem("filterProductiveOnly", JSON.stringify(filterProductiveOnly));
        localStorage.setItem("filterMonthRelative", JSON.stringify(filterMonthRelative));
    }, [gridApi, filterProductiveOnly, filterMonthRelative]);

    // quick filter customizations
    const [quickFilter, setQuickFilter] = useState("");
    // NOTE: we use a custom quick filter for the status column that - when the line is "new" - returns the quick filter text itself, so its always visible
    // that is a bit unconventional, but it works well
    const statusQuickFilterText = useDynamicCallback(params => {
        const { data } = params;
        if (data.status.id === rowStatus.new.id) {
            return quickFilter;
        } else {
            return "";
        }
    });
    const openprojectIDQuickFilterText = useDynamicCallback(params => {
        const { value } = params;
        // NOTE: we re-use the columns' cell-renderer component to produce the openproject description from the ID
        const description = OpenprojectWorkPackageSelectorRenderer({value: value, entries: openprojectWorkpackagesRef.current});
        return description;
    });

    return (
        <div style={{ height: "100%" }}>
            {/* Button Toolbar */}
            <TimeRecordsButtonToolbar
                disableAllButtons={ !rowData || loading } // disable buttons if rowData is null or in loading state
                gridDirty={gridDirty}
                newRows={newRows}
                markRowAsDeleted={markRowAsDeleted}
                filterModel={filterModel}
                setFilterGlobal={setQuickFilter}
                filterGlobal={quickFilter}
                setFilterMonthRelative={setFilterMonthRelative}
                filterMonthRelative={filterMonthRelative}
                setFilterProductiveOnly={setFilterProductiveOnly}
                filterProductiveOnly={filterProductiveOnly}
                resetAllFilters={resetAllFilters}
                columnState={columnState}
                sortDateAndTime={sortDateAndTime}
                sortOpenProjectID={sortOpenProjectID}
                resetAllSortings={resetAllSortings}
                autoSizeAllColumns={autoSizeAllColumns.bind(this)}
                resetColumnState={resetColumnState}
                save={save}
                refresh={refreshButtonPressed}
                copyRows={copyRows}
                selectAll={selectVisible}
                excelExport={() => {
                    gridApi.exportDataAsExcel({fileName: 'timecontrol_export.xlsx'});
                }}
                hours={hours}
                rows={rows}
            />

            <div className="time-records" style={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: 0, height: "calc(100% - 95px)" }}>
                {feedbackMsg && (
                    <div style={{ paddingBottom: "8px" }}>
                        <Alert message={feedbackMsg} type={feedbackIsError? "error" : "success"}showIcon/>
                    </div>
                )}
                <div className="ag-theme-balham" style={{height: '100%'}}>
                    <AgGridReact
                        // grid options
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        animateRows={true}
                        quickFilterText={quickFilter}
                        isExternalFilterPresent={() => true}
                        doesExternalFilterPass={externalFilterPass}
                        rowSelection="multiple"
                        onCellValueChanged={updateCellValue}
                        enableRangeSelection={true}
                        suppressMultiRangeSelection={true}
                        suppressRowClickSelection={true}
                        components={{
                            dateAndTimePickerEditor: DateAndTimePickerEditor,
                            classificationEditor: ClassificationEditor,
                            boolRenderer: BoolRenderer,
                            openprojectWorkPackageSelectorEditor: OpenprojectWorkPackageSelectorEditor,
                            openprojectWorkPackageSelectorRenderer: OpenprojectWorkPackageSelectorRenderer,
                            timePickerEditor: TimePickerEditor
                        }}
                        getRowId={function (data) {
                            return data.data.id;
                        }}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Loading...</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span class="ag-overlay-loading-center">No data.</span>'
                        }
                        onFilterChanged={onFilterChanged}
                        onSortChanged={onSortChanged}
                        onColumnMoved={onColumnStateChanged}
                        onColumnResized={onColumnStateChanged}
                        processCellFromClipboard={processCellFromClipboard}
                        processCellForClipboard={processCellForClipboard}
                    />
                </div>
            </div>
        </div>
    );

    // ######################################## INIT FUNCTIONS ########################################

    // grid ready
    function onGridReady(params) {
        // set states
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        // HACK: Create event listener and listen for clicks in "App" to stop editing, when grid loses focus
        // NOTE: Sadly, agGrid's gridOption 'stopEditingWhenGridLosesFocus' won't work with custom cellEditors.
        // (It would also stop editing, when cellEditor is being used.)
        // disabled hack by mhof 2021-08-24 as it will end edit on a click inside textbox; very distracting when using like a regular textbox
        // props.appRef.current.addEventListener("click", () => params.api.stopEditing());

        // refresh data
        refresh(params);    // passing params to refresh() needed, because setting states may be too lazy and refresh() will need 'gridApi' and 'gridColumnApi'
    }

    // Init columnDefs
    function initColumnDefs() {
        return [
            {
                headerName: "Status",
                field: "status",
                editable: false,
                checkboxSelection: true, // checkbox for selecting row
                pinned: "left", // pinn to the left
                // set width = minWidth = maxWith, so fitting is suppressed in every possible way
                width: 92,
                minWidth: 92,
                maxWidth: 92,
                resizable: false,
                suppressSizeToFit: true, // suppress sizeToFit
                // get name of status
                valueGetter: (params) => params.data?.status?.name,
                cellStyle: { fontStyle: "italic" },
                getQuickFilterText: (node) => statusQuickFilterText(node)
            },
            {
                headerName: "Id",
                field: "id",
                editable: false,
                width: 92,
                hide: true,
            },
            {
                headerName: "Date",
                field: "date",
                type: "date",
                //comparator: dateComparator,
                width: 100,
                cellEditorPopup: true,
                suppressKeyboardEvent: params => {
                        const key = params.event.key;
                        const gridShouldDoNothing = params.editing && key === 'Enter';
                        return gridShouldDoNothing;
                    },
            },
            {
                headerName: "From",
                field: "from",
                type: "time",
                //comparator: timeComparator,
                width: 80,
                cellEditorPopup: true
            },
            {
                headerName: "To",
                field: "to",
                type: "time",
                //comparator: timeComparator,
                width: 80,
                cellEditorPopup: true
            },
            {
                headerName: "Hours",
                field: "hours",
                editable: false,
                width: 75,
                valueGetter: (params) => {
                    const hours = calculateHours(params.data?.["from"], params.data?.["to"], params.data?.["factor"]);
                    return hours;
                },
                cellStyle: { fontStyle: "italic" },
            },
            {
                headerName: "Activity",
                field: "activity",
                width: 400,
            },
            {
                headerName: "OpenProject ID",
                field: "openproject_id",
                type: "openprojectID",
                width: 400,
                cellEditorPopup: true
            },
            {
                headerName: "Location",
                field: "location",
            },
            {
                headerName: "Pool",
                field: "pool",
                width: 200,
                editable: false,
                cellStyle: { fontStyle: "italic" },
            },
            {
                headerName: "Grp1",
                field: "grp1",
                width: 200,
                editable: false,
                cellStyle: { fontStyle: "italic" },
            },
            {
                headerName: "Line Classification",
                field: "classification",
                type: "classification",
            },
            {
                headerName: "Factor",
                field: "factor",
                width: 75,
            },
            {
                headerName: "Productive",
                field: "productive",
                type: "bool",
                width: 100,
            },
            {
                headerName: "Read-Only",
                field: "readonly",
                editable: false,
                hide: true,
            },
        ];
    }

    // Init defaultColDef
    function initDefaultColDef() {
        return {
            sortable: true,
            filter: true,
            editable: params => props.enableGridEditing ? !params.data?.readonly : false,
            cellStyle: params => params.data?.readonly && { fontStyle: "italic" },
            resizable: true,
            width: 150,
            cellClassRules: {
                // specified in css
                new: function (params) {
                    if (params.data?.status.id === rowStatus.new.id) return true;
                },
                edited: function (params) {
                    if (params.data?.status.id === rowStatus.edited.id) return true;
                },
                clean: function (params) {
                    if (params.data?.status.id === rowStatus.clean.id) return true;
                },
                deleted: function (params) {
                    if (params.data?.status.id === rowStatus.deleted.id)return true;
                },
            },
        };
    }

    // Init columnTypes
    function initColumnTypes() {
        return {
            date: {
                cellEditor: "dateAndTimePickerEditor",
                filter: "agDateColumnFilter",
                filterParams: {
                    // custom comparator for date comparison - ignores time in date object
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        if (cellValue) {
                            let filterDate = DateTime.fromJSDate(filterLocalDateAtMidnight);
                            let cellDate = DateTime.fromISO(cellValue).set({hour: 0, minutes: 0});

                            if (filterDate.toMillis() === cellDate.toMillis()) return 0;
                            else if (cellDate < filterDate) return -1;
                            else if (cellDate > filterDate) return 1;
                        }
                    },
                    filterOptions: [
                        "equals",
                        "inRange",
                        "lessThanOrEqual",
                        "greaterThanOrEqual",
                    ],
                    defaultOption: "greaterThanOrEqual",
                    inRangeInclusive: true,
                    closeOnApply: true,
                    minValidYear: 2000,
                },
                
                cellRenderer: (params) => {
                    let dt = new  DateTime.fromISO(params.value);
                    return dt.setLocale('de').toLocaleString(DateTime.DATE_SHORT);
                }
            
            },
            time: {
                cellEditor: "timePickerEditor",
                
                cellRenderer: (params) => {
                    let dt = new DateTime.fromISO(params.value);
                    return dt.toLocaleString(DateTime.TIME_24_SIMPLE);
                },
                
               /*
                valueGetter: (params) => {
                    let value = params.data?.[params.colDef.field];
                    let dt = DateTime.fromISO(value)
                    if (value) return dt.toLocaleString(DateTime.TIME_24_SIMPLE);
                    else return null;
                },
                */
               /*
                valueSetter: (params) => {
                    if (params.newValue) {
                        let oldDate = DateTime.fromISO(params.data[params.colDef.field]);
                        let newValueArray = params.newValue.split(":");
                        let newDate = oldDate.set({hour: newValueArray[0], minute: newValueArray[1]});
                        let newDateUTC = newDate.setZone("UTC");
                        params.data[params.colDef.field] = newDateUTC.toISO();
                        return true;
                    } else return false;
                },
                */
            },
            bool: {
                // has to be editable in order to work with aggrid_copy_cut_paste-module
                cellRenderer: "boolRenderer",
                valueParser: (params) => {
                    return params.newValue === "false" || !params.newValue  ? false : true;
                }
            },
            classification: {
                cellEditor: "classificationEditor",
                valueGetter: (params) => {
                    let value = params.data?.[params.colDef.field];
                    if (value) {
                        return value.charAt(0).toUpperCase() + value.slice(1); // first letter to upper case
                    } else return null;
                },
            },
            openprojectID: {
                cellRenderer: "openprojectWorkPackageSelectorRenderer",
                cellRendererParams: () => {
                    return {
                        entries: openprojectWorkpackagesRef.current
                    };
                },
                cellEditor: "openprojectWorkPackageSelectorEditor",
                cellEditorParams: () => {
                    return {
                        entries: openprojectWorkpackagesRef.current
                    };
                },
                suppressKeyboardEvent: function (params) {
                    // HACK: disable the enter key while editing is ongoing
                    // ideally, we'd do that inside the celleditor, but that did not work properly
                    if (params.editing) {
                        var KEY_ENTER = 13;
                        var key = params.event.which;
                        return key === KEY_ENTER;
                    } else return false;
                },
                getQuickFilterText: (node) => openprojectIDQuickFilterText(node),
            }
        };
    }

    function refreshButtonPressed() {
        const columnStateBeforeRefresh = gridColumnApi.getColumnState();

        refresh().then(() => {
            gridColumnApi.applyColumnState({state: columnStateBeforeRefresh, applyOrder: true});
        })
    }
    // refresh
    async function refresh(agGridParams) {

        // agGridParams only set, when called from onGridReady()
        const gridApiHere = agGridParams ? agGridParams.api : gridApi;
        const gridColumnApiHere = agGridParams ? agGridParams.columnApi : gridColumnApi;

        const filterModel = JSON.parse(localStorage.getItem("filterModel"));    // get local storage item
        const columnState = JSON.parse(localStorage.getItem("columnState"));    // get local storage item
        // remove feedback msg, set loading
        setFeedbackMsg("");
        setFeedbackIsError(false);
        setLoading(true);
        setColumnState(columnState);

        // Tell AgGrid to reset rowData // important!
        if (gridApiHere) {
            gridApiHere.setRowData(null);
            gridApiHere.showLoadingOverlay(); // trigger "Loading"-state (otherwise would be in "No Rows"-state instead)
        }
        try {
            const timerecords = await getDataFromServer("timerecords"); // get timerecords
            timerecords.forEach(timerecord => {
                timerecord.date = timerecord.from;      // copy date from 'from' to 'date'
                timerecord.status = rowStatus.clean;    // set all stati to 'clean'
            });

            const openprojectWorkPackages = await getDataFromServer("openproject/workpackages");
            const stringifiedOpenprojectWorkPackages = openprojectWorkPackages.map(f => {
                return {
                    id: f.id.toString(), // NOTE: we work with strings for openproject workpackage keys/ids throughout the codebase
                    name: '#' + f.id + ' ' + f.fullpathsubject + ' (' + f.project_fullpathname + ')'
                };
            })
            openprojectWorkpackagesRef.current = stringifiedOpenprojectWorkPackages;



            // set states
            setRowData(timerecords);
            setTempId(0);
            setFilterModel(filterModel);
            //setColumnState(columnState);
            setLoading(false);
            setGridDirty(false);

            if (gridApiHere) {
                gridApiHere.setRowData(timerecords);        // Tell AgGrid to set rowData
                gridApiHere.setFilterModel(filterModel);    // set filterModel
                calculateSum(gridApiHere)
            }
            if (gridColumnApiHere && columnState) {
                //console.log('applying column state in refresh',columnState);
                gridColumnApiHere.applyColumnState({state: columnState, applyOrder: true}); // set columnState
            }
            props.debugLog(false, ">> REFRESHING END");
        } catch (e) {
            setFeedbackMsg(e.toString());
            setFeedbackIsError(true);
            setLoading(false);
            props.debugLog(true, e);
        }
    };

    function processCellFromClipboard(params) {
        switch(params.column.colId) {
            case 'classification':
                return params.value.toLowerCase();
                break;
            case 'date':
                let dt = DateTime.fromFormat(params.value,"dd.LL.y");
                return dt.toISO();
                break;
            case 'from':
            case 'to':
                // get the date
                let tt = DateTime.fromISO(params.node.data.date);
                let timeArr = params.value.split(":");
                return tt.set({hour: timeArr[0], minute: timeArr[1]}).toISO();
                break;
            default:
                return params.value;
                break;
        }
    }

    function processCellForClipboard(params) {
        switch(params.column.colId) {
            case 'date':
                let dt = new  DateTime.fromISO(params.value);
                return dt.setLocale('de').toLocaleString(DateTime.DATE_SHORT);
                break;
            case 'from':
            case 'to':
                let ft = new DateTime.fromISO(params.value);
                return ft.toLocaleString(DateTime.TIME_24_SIMPLE);
                break;
            default:
                return params.value;
        }
    }

    // ######################################## FILTER ########################################

    // filter changed
    function onFilterChanged() {
        const filterModel = gridApi.getFilterModel();
        if (_.size(filterModel)) {
            setFilterModel(filterModel);
            localStorage.setItem("filterModel", JSON.stringify(filterModel));    // set local storage item
        } else {
            setFilterModel(null);
            localStorage.removeItem("filterModel"); 
        }
        calculateSum();
    }

    // reset all filters - does not affect search-field - filterGlobal()
    function resetAllFilters() {
        setFilterMonthRelative(null);
        setFilterProductiveOnly(false);
        setFilterModel(null);
        gridApi.setFilterModel(null);  // reset FilterModel
    }

    // ######################################## SORT ########################################

    // sorting changed
    function onSortChanged() {
        onColumnStateChanged();
    }

    // sort date and then, filter
    function sortDateAndTime() {
        const defaultSortModel = [{colId: "date", sort: "asc", sortIndex: 0}, {colId: "from", sort: "asc", sortIndex: 1}];
        gridColumnApi.applyColumnState({
            state: defaultSortModel,
            defaultState: {sort: null}
        })
        setColumnState(gridColumnApi.getColumnState());
        //localStorage.setItem('columnState', JSON.stringify(gridColumnApi.getColumnState())); // set local storage item
    }

    // sort openproject_id
    function sortOpenProjectID() {
        const defaultSortModel = [{colId: "openproject_id", sort: "asc"}];
        gridColumnApi.applyColumnState({
            state: defaultSortModel,
            defaultState: {sort: null}
        })
        setColumnState(gridColumnApi.getColumnState());
    }

    // reset all sortings
    function resetAllSortings() {
        gridColumnApi.applyColumnState({
            defaultState: {sort: null}
        });
        setColumnState(gridColumnApi.getColumnState());
    }

    // ######################################## COLUMN STATE ########################################

    // column state changed
    function onColumnStateChanged() {
        const columnState = gridColumnApi.getColumnState();
        localStorage.setItem('columnState', JSON.stringify(columnState)); // set local storage item
        //setColumnState(columnState);
    }

    // resize table and fit to column sizes
    function autoSizeAllColumns() {
        gridColumnApi.autoSizeAllColumns();
    }

    // reset column state
    function resetColumnState() {
        gridColumnApi.resetColumnState();    // reset columnState
        setColumnState(gridColumnApi.getColumnState());
    }

    // ######################################## CELL EDITING ########################################

    // update cell
    function updateCellValue(e) {
        var rowNode = gridApi.getRowNode(e.data.id);
        // ignore changes of 'status'
        if (rowNode && e.colDef.field !== "status") {
            const oldValue =
                e.oldValue === null || e.oldValue === undefined // [not set]-Attributes: null, undefined
                    ? null // set [not set]-Attributes to null, so js-comparison does not detect a difference
                    : e.oldValue; // [set]-Attributes: e.g. "" (empty string), any other set value

            // analog to oldValue
            const newValue =
                e.newValue === null || e.newValue === undefined
                    ? null
                    : e.newValue;

            // ignore unchanged data
            if(newValue !== oldValue) {
                // check if 'factor' contains a comma and if so, change to dot
                if(e.colDef.field === "factor" && newValue.indexOf(',') !== -1)
                    rowNode.setDataValue("factor", newValue.replace(',','.'));
                
                // set grid dirty, if 'status' is not 'new'
                if (e.data.status.id !== rowStatus.new.id) {
                    rowNode.setDataValue("status", rowStatus.edited);
                    setGridDirty(true);
                    props.debugLog( false, "cell value changed to: " + e.newValue );
                }
            }
        }
    };

    // ######################################## ROW EDITING ########################################

    // new row
    function newRows(e) {
        var numberOfNewRows = e.currentTarget.value; // how many rows to add
        const today = DateTime.now();

        let rowsToAdd = [];
        for (var i = 0; i < numberOfNewRows; i++) {
            rowsToAdd.push({
                status: rowStatus.new,
                id: "_t_" + tempId + i,
                date: today.toISO(),
                from: today.minus({hour: 1}).toISO(),
                to: today.toISO(),
                classification: "work",
                factor: 1,
                productive: true,
                readonly: false,
                openproject_id: ""
            });
        }

        setTempId(tempId + numberOfNewRows); // set tempId
        gridApi.applyTransaction({ add: rowsToAdd }); // add all new rows at once
        setGridDirty(true);
        props.debugLog(false, "added " + numberOfNewRows + " new row(s)");
    };

    // mark row as 'deleted'
    function markRowAsDeleted() {
        var selectedData = gridApi.getSelectedRows();
        var numberOfNewRows = selectedData.length; // how many rows to add
        for (var i = 0; i < numberOfNewRows; i++) {
            var rowNode = gridApi.getRowNode(selectedData[i].id);
            // directly delete entry, when "new"
            if (rowNode && selectedData[i].status.id === rowStatus.new.id) {
                gridApi.applyTransaction({ remove: [selectedData[i]] });
                props.debugLog(false, "delete row");
            }
            // set status to "deleted", when not "new"
            else if (rowNode) {
                rowNode.setDataValue("status", rowStatus.deleted);
                setGridDirty(true);
                props.debugLog(false, "marked row as 'deleted'");
            }
        }
    };

    // copy rows
    function copyRows() {
        let selectedData = gridApi.getSelectedRows();
        let rowsToAdd = [];
        for(let i in selectedData) {
            let fromDate = DateTime.fromISO(selectedData[i].from);
            let toDate = DateTime.fromISO(selectedData[i].to);

            rowsToAdd.push({
                status: rowStatus.new,
                id: "_t_"+tempId+i,
                date: DateTime.now().toISO(),
                // we do not want to be it now, changed to preserve time as use case is to copy a jour fixe
                // from: dateAndTimeParser.parseTimeToDateObj(dateAndTimeParser.addHoursToDate(today, -1)),
                // to: dateAndTimeParser.parseTimeToDateObj(today),
                from: DateTime.now().set({hour: fromDate.hour, minute: fromDate.minute}).toISO(),
                to: DateTime.now().set({hour: toDate.hour, minute: toDate.minute}).toISO(),
                classification: selectedData[i].classification,
                factor: selectedData[i].factor,
                productive: selectedData[i].productive,
                location: selectedData[i].location,
                activity: selectedData[i].activity,
                openproject_id: selectedData[i].openproject_id,
                readonly: false
            });
        }
        setTempId(tempId+selectedData.length);
        gridApi.applyTransaction({add: rowsToAdd});
        gridApi.deselectAll();  // deselect rows
        setGridDirty(true);
        props.debugLog(false, "copied " + rowsToAdd.length + " new row(s)");
    }

    function selectVisible() {
        var model = gridApi.getModel();
        let rowCount = model.getRowCount();
        for (var i = 0; i<rowCount; i++) {
            var rowNode = model.getRow(i);
            rowNode.setSelected(true);
        }
    }

    // ######################################## CRUD OPERATIONS ########################################

    // READ - returns data with given entity name
    async function getDataFromServer(entityName) {
        // GET - /api/timerecords
        try {
            var token = localStorage.getItem("token");
            const entries = await _CRUDController.read(
                props.baseUrl + "/" + entityName,
                token
            );
            return entries;
        } catch (e) {
            props.debugLog(true, e);
        }
    };

    // CREATE / UPDATE / DELETE on pressing 'save'
    async function save() {
        // remove feedback msg, set loading
        setFeedbackMsg("");
        setFeedbackIsError(false);
        setLoading(true);

        var urlTimerecords = props.baseUrl + "/timerecords";
        var token = localStorage.getItem("token");
        await gridApi.forEachNode(async (node) => {
            try {
                // CREATE - POST - /api/timerecords
                if (node.data.status.id === rowStatus.new.id) {
                    // from and to are already in the correct format for the database
                    let entry = await _CRUDController.create(
                        urlTimerecords,
                        node.data,
                        token
                    );
                    setLoading(false);
                    setGridDirty(false);
                    entry.date = entry.from;          // copy date from 'from' to 'date'
                    entry.status = rowStatus.clean;              // add status 'clean'
                    gridApi.applyTransaction({ remove: [node] });           // remove old node 
                    gridApi.applyTransaction({ add: [entry] });  // add new node (updating not possible, because id changed)
                    calculateSum();

                    // UPDATE - /api/timerecords/{id}
                } else if (node.data.status.id === rowStatus.edited.id) {
                    // from and to are already correct ISO-Strings for the database
                    let entry = await _CRUDController.update(
                        urlTimerecords,
                        node.data.id,
                        node.data,
                        token
                    );
                    setLoading(false);
                    setGridDirty(false);
                    entry.date = entry.from;          // copy date from 'from' to 'date'
                    entry.status = rowStatus.clean;              // add status 'clean'
                    node.setData(entry);                         // set timerecord
                    calculateSum();

                    // DELETE - /api/timerecords/{id}
                } else if (node.data.status.id === rowStatus.deleted.id) {
                    // delete, remove from server
                    await _CRUDController.remove(
                        urlTimerecords,
                        node.data.id,
                        token
                    );
                    setLoading(false);
                    setGridDirty(false);
                    gridApi.applyTransaction({ remove: [node] }); // delete from grid
                    calculateSum();
                }
            } catch (e) {
                setFeedbackMsg(e.toString());
                setFeedbackIsError(true);
                setLoading(false);
                props.debugLog(true, e);
            }
        });
    };

    // ######################################## SUMMING ########################################

    function calculateSum(api) {
        // after a reload gridApi is not defined (yet) - so we have to use the gridApiHere from the refresh function
        if(api === undefined) {
            api = gridApi;
        }
        let hours = 0;
        let rows = 0;

        api.rowModel.rowsToDisplay.forEach(node => {
            hours += api.getValue('hours',node);
            rows++;
        });

        setHours(hours);
        setRows(rows);
    }

    // calculates the duration between two Date()-Objects, but ignores Date, with respect to given factor
    function calculateHours(from, to, factor) {
        let f = DateTime.fromISO(from);
        let t = DateTime.fromISO(to);
        let hours = t.diff(f,'hours').toObject();
        return hours.hours*factor;
    }
}
